import {
    AmazonType,
    ApiType,
    AppConfiguration,
    AppType,
    AsignaturaType,
    CarrerasConf,
    EvaluacionTipoMap,
    HojaRespuestaType,
    PlataformaType
} from "@puntaje/shared/core"

export class BaseConfig implements AppConfiguration {
    navegacion = {
        urlHistorial: ["/evaluaciones/resultados/historial"]
    }

    generar = {
        generar: false,
        intrumentosRecomendados: false,
        pruebasPropias: false,
        buscador: true
    }

    registro = {
        enableCarreras: true,
        universidades: [],
        amount: 3,
        enableNivel: true,
        enableColegio: true
    }
    carreras = {
        ponderables: [
            {
                orden: 1,
                ponderable: "Matemática",
                asignaturaAsociada: "Matemática",
                tipo: "calificable"
            },
            {
                orden: 2,
                ponderable: "Lenguaje y Comunicación",
                asignaturaAsociada: "Lenguaje",
                tipo: "calificable"
            },
            {
                orden: 3,
                ponderable: "Historia y Ciencias Sociales",
                asignaturaAsociada: "Historia y Ciencias Sociales",
                tipo: "calificable",
                categoria: "ciencias-historia"
            },
            {
                orden: 4,
                ponderable: "Ciencias",
                asignaturaAsociada: "Ciencias PSU",
                tipo: "calificable",
                categoria: "ciencias-historia"
            },
            {
                orden: 5,
                ponderable: "NEM",
                alias: "Puntaje NEM",
                clase: "nem",
                tipo: "ingresable",
                field: "nem",
                minValue: 150,
                maxValue: 850
            },
            {
                orden: 6,
                ponderable: "Ranking",
                clase: "ranking",
                tipo: "ingresable",
                field: "ranking",
                minValue: 150,
                maxValue: 850
            }
        ]
    } as CarrerasConf
    app = {
        assets: {
            logoLarge: "assets/img/logos/logoLarge.png",
            logoMedium: "assets/img/logos/logoMedium.png",
            logoSmall: "assets/img/logos/logoSmall.png",
            logoLargeLight: "assets/img/logos/logoLargeLight.png",
            logoMediumLight: "assets/img/logos/logoMediumLight.png",
            logoSmallLight: "assets/img/logos/logoSmallLight.png",
            logoSquare: "assets/img/logos/logoSquare.png",
            logoSquareLight: "assets/img/logos/logoSquareLight.png",
            streamingOffline: ""
        },
        name: "profesores",
        loader: "aprendolibre2",
        routeUser: null,
        routeUserInfo: null,
        paths: {
            pendingInfo: "/pending_info",
            landing: "/landing"
        },
        baseStyle: {
            navbar: {
                logo: "logoSmall",
                logoMobile: "logoSquare"
            },
            footer: {},
            landing: {},
            sharedLayout: {}
        },
        menuConfig: {},
        showcasesByApp: {
            alumnos: [],
            profesores: []
        },
        navigationByApp: {
            alumnos: [
                {
                    icon: "libro2-o",
                    name: "Blog",
                    route: "/blog"
                },
                {
                    icon: "editar-prueba",
                    name: "Ejercita",
                    route: "/pruebas/generar_prueba"
                },
                {
                    icon: "prueba",
                    name: "Practica",
                    route: "/evaluaciones/recomendadas"
                },
                {
                    icon: "resultados",
                    name: "Resultados",
                    route: "/pruebas/resultados"
                },
                {
                    icon: "planificacion-o",
                    name: "Planes adaptativos",
                    route: "/planes_personales"
                },
                {
                    icon: "planificacion-o",
                    name: "Planes de clase",
                    route: "/plan_clases"
                },
                {
                    icon: "dialogos",
                    name: "Dudas del curso",
                    route: "/dudas"
                },
                {
                    icon: "dialogos",
                    name: "Comunidad",
                    route: "/dudas_categorias"
                },
                {
                    icon: "dialogos",
                    name: "Centro de ayuda",
                    route: "/ayudas"
                },
                {
                    icon: "establecimiento-o",
                    name: "Carreras",
                    route: "/mis_carreras"
                }
            ],
            profesores: [
                {
                    icon: "libro2-o",
                    name: "Blog",
                    route: "/blog"
                },
                {
                    icon: "editar-prueba",
                    name: "Evaluar",
                    route: "/pruebas/generar_prueba"
                },
                {
                    icon: "upload",
                    name: "Corregir",
                    route: "/evaluacion_instancias/subir"
                },
                {
                    icon: "resultados",
                    name: "Resultados",
                    route: "/evaluaciones/resultados"
                },
                {
                    icon: "planificacion-o",
                    name: "Planes de clase",
                    route: "/plan_clases"
                },
                {
                    icon: "megafono-o",
                    name: "Mis noticias",
                    route: "/noticias"
                },
                {
                    icon: "planificacion-o",
                    name: "Planes de clase predefinido",
                    route: "/plan_clases_predefinidos"
                },
                {
                    icon: "dialogos",
                    name: "Comunidad",
                    route: "/dudas_categorias"
                },
                {
                    icon: "dialogos",
                    name: "Centro de ayuda",
                    route: "/ayudas"
                },
                {
                    icon: "resultados",
                    name: "Resumen de uso",
                    route: "/estadisticas/resumen_uso"
                }
            ]
        }
    } as AppType

    amazon = {
        s3BucketUri: "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/",
        s3BucketUriNebu: "https://s3-us-west-1.amazonaws.com/bucket-nebuloso/"
    } as AmazonType

    api = {
        checkTokenPath: "/check_token",
        authPath: "/authenticate"
    } as ApiType

    asignaturas = [
        {
            id: null,
            asignatura: "Matemática",
            icono: "matematica",
            clase: "matematica",
            abreviacion: "Matemática",
            asignaturaPlataforma: "Matemática",
            evaluacionTipo: ["paes"],
            materialVisible: true
        },
        {
            id: null,
            asignatura: "Lenguaje",
            icono: "lenguaje",
            clase: "lenguaje",
            abreviacion: "Comprensión Lectora",
            asignaturaPlataforma: "Comprensión Lectora",
            evaluacionTipo: ["paes"],
            materialVisible: true
        },
        {
            id: null,
            asignatura: "Ciencias Naturales",
            icono: "ciencias",
            clase: "ciencias",
            abreviacion: "Ciencias",
            asignaturaPlataforma: "Ciencias",
            evaluacionTipo: ["paes"],
            materialVisible: true
        },
        {
            asignatura: "Historia y Ciencias Sociales",
            icono: "historia",
            clase: "historia",
            abreviacion: "Historia y Ciencias Sociales",
            asignaturaPlataforma: "Historia ",
            evaluacionTipo: ["paes"],
            materialVisible: true
        }
    ] as AsignaturaType[]

    plataforma = {
        name: "ensayosuniversidades",
        id: null,
        pais: "chile",
        locale: "es-cl",
        lugarTipo: "Comuna",
        lugarLabel: "Comuna",
        identificadorUsuario: ["RUT"],
        identificadorEstablecimiento: ["rbd"],
        grupoUsuarioAlias: "curso",
        clasificacionTipoNivel: "curso",
        info: {
            social: {
                facebook: "https://www.facebook.com/aprendolibre.co/",
                twitter: "https://twitter.com/aprendolibre",
                youtube: "https://www.youtube.com/channel/UCTzMx0G4b7hLXDycu3DISjw"
            },
            soporte: {
                mail: "monitores@aprendolibre.co"
            },
            contacto: {
                telefono: "00000000",
                mail: "ventas@ogr.cl"
            },
            companyName: "Aprendolibre.net",
            companySite: "www.aprendolibre.net"
        },
        evaluacionTipoAdaptiveDefault: "paes",
        evaluacionDefault: "paes",
        evaluacionResponder: "estandar",
        resumenGeneral: {
            curriculum: "curricular",
            generadores: [83, 15, 16, 17, 18, 84, 19, 20, 85]
        },
        tiposInstrumentoInformeUso: [],
        evaluacionTipos: ["paes"],
        añoInicial: 2020,
        periodos: [
            {
                label: "",
                fechaInicial: [0, 1],
                fechaFinal: [11, 31, 23, 59, 59]
            }
        ],
        streamingDefault: null,
        iconSet: {
            noticiasShowcase: "megafono-o",
            establecimientosShowcase: "establecimiento-o",
            cursosShowcase: "comunidad-o"
        },
        instrumentoMensualTipo: "curricular",
        tiposNotificacion: [
            {
                tipo: "curricular mexico",
                label: "Pruebas"
            },
            {
                tipo: "duda",
                label: "Dudas"
            },
            {
                tipo: "respuesta_correcta_duda",
                label: "Respuestas Correctas Dudas"
            },
            {
                tipo: "respuesta_duda",
                label: "Respuestas Dudas"
            },
            {
                tipo: "retroalimentacion",
                label: "Retroalimentaciones"
            }
        ],
        identificadorUsuarioAlias: null
    } as PlataformaType

    evaluaciones = {
        paes: {
            alias: "PAES",
            cursos: [
                {
                    label: "7º Medio",
                    clasificacion: "Séptimo Básico"
                },
                {
                    label: "8º Medio",
                    clasificacion: "Octavo Básico"
                },
                {
                    label: "1º Medio",
                    clasificacion: "Primero Medio"
                },
                {
                    label: "2º Medio",
                    clasificacion: "Segundo Medio"
                },
                {
                    label: "3º Medio",
                    clasificacion: "Tercero Medio"
                },
                {
                    label: "4º Medio",
                    clasificacion: "Cuarto Medio"
                }
            ],
            cursoTipo: "curso",
            estrellas: [
                {
                    max: 266,
                    min: 150
                },
                {
                    max: 383,
                    min: 267
                },
                {
                    max: 500,
                    min: 384
                },
                {
                    max: 617,
                    min: 501
                },
                {
                    max: 734,
                    min: 618
                },
                {
                    max: 850,
                    min: 735
                }
            ],
            cursosMaterial: [],
            clasificaciones: {
                groupBy: ["Eje PAES,Unidad Temática PAES"],
                methods: "clasificaciones",
                estadisticas: ["Eje PAES"],
                referencesTo: {
                    "Unidad Temática PAES": "Eje PAES"
                },
                configuration: [
                    {
                        name: "Eje PAES",
                        htmlId: "Eje_PAES",
                        hasHijos: true,
                        clasificacionTipo: "Eje PAES"
                    }
                ],
                cambiarPregunta: ["nivel 1 prueba de transición"],
                desempenoEvaluacion: "Eje PAES",
                estadisticasEvolutivas: ["Eje PAES"],
                clasificacionTiposTablaEspecificaciones: ["Eje PAES", "Unidad Temática PAES"]
            },
            cantidadPreguntas: 5,
            rangoCalificacion: {
                max: 1000,
                min: 100,
                tickInterval: 50
            },
            clasificacionMaterial: {
                label: "eje",
                clasificacion: "Eje PAES"
            },
            streamingChannelAlias: "Clases Prueba de transición",
            clasificacionDistribucion: "ejes",
            clasificacionTiposEstadisticas: [
                {
                    nombre: "Eje PAES",
                    alumnos: true,
                    compuesto: true,
                    profesores: true,
                    informeAnual: true
                },
                {
                    nombre: "Unidad Temática PAES",
                    alumnos: true,
                    compuesto: true,
                    profesores: true,
                    informeAnual: false
                },
                {
                    nombre: "Habilidad PAES",
                    alumnos: false,
                    compuesto: true,
                    profesores: true,
                    informeAnual: true
                }
            ],
            nivelTipoInstrumentoInformeFinal: ["1º Medio", "2º Medio", "3º Medio", "4º Medio"],
            planPersonalClasificacionMateriales: []
        }
    } as EvaluacionTipoMap

    instrumentosPredefinidos = {
        zonas: ["Columna 1", "Columna 2", "Columna 3"]
    }

    hojaRespuesta = {
        sectores: [
            [1, 2],
            [2, 3],
            [3, 4],
            [5, 2],
            [2, 7],
            [7, 4],
            [5, 6],
            [6, 7],
            [7, 8]
        ],
        alternativas: 5
    } as HojaRespuestaType

    mensajesLogin = {
        idPlaceholder: "Email o NUIP",
        invalidLoginId: "Debes incluir un correo electrónico o NUIP válido.",
        invalidLoginMessage1:
            "<strong>La combinación de correo electrónico/contraseña o NUIP/contraseña es incorrecta.</strong> Las contraseñas de ",
        invalidLoginMessage2:
            ' distinguen entre mayúsculas y minúsculas. Por favor, comprueba la tecla "Bloq Mayús". También puedes probar a borrar el caché y las cookies de tu navegador.'
    }
}
